<template>
  <div class="pt-12 mt-6">
    <v-container>
      <v-row>
        <v-col cols="12" class="text-center">
          <h1 class="display-1 mb-4 font-weight-bold">
            Kullanım Şartları
          </h1>
          <p>
            UTEx kapsamında sunulan tüm hizmetler burada sunulan kullanım
            şartlarına ve politikalara göre düzenlenmektedir.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="mb-4">
          <h3>1. Giriş</h3>
          <p>
            BU KULLANIM ŞARTLARI ("ŞARTLAR"), SİZİN, TEMSİL ETTİĞİNİZ ŞİRKETİN
            VE SİZİ BORDROLAYAN ŞİRKETİN ("SİZ," VEYA "SİZİN"), UTE AKADEMİ LTD.
            ŞTİ. ("BİZİM", "BİZ", "KURUM" VEYA “UTE AKADEMİ”) YAZILIMINI, WEB
            SİTESİNİ (UTEAKADEMI.COM), MOBİL UYGULAMALARINI VEYA WEB SİTEMİZDE
            SUNULAN DİĞER ŞEYLERİ (TOPLUCA, "HİZMETLER") KULLANIMIYLA BAĞLANTILI
            OLARAK BURADA BELİRTİLEN HÜKÜM VE KOŞULLARA UYMASINI GEREKTİRİR. UTE
            AKADEMİ HİZMETLERİNDEN HERHANGİ BİRİNİ KULLANDIĞINIZ VEYA "HESAP
            OLUŞTUR" BUTONUNA TIKLADIĞINIZDA, BU ŞARTLARA TABİ OLDUĞUNUZU KABUL
            EDERSİNİZ. TÜM BU ŞARTLARI KABUL ETMİYORSANIZ, "X" BUTONUNA TIKLAYIN
            VE UTE AKADEMİ HİZMETLERİNİ KULLANMAYIN. UTE AKADEMİNİN KABUL
            ETMESİ, DİĞER TÜM ŞARTLAR HARİÇ OLACAK ŞEKİLDE, SİZİN TÜM BU
            ŞARTLARI KABUL ETMENİZE AÇIK BİÇİMDE KOŞULLANDIRILMIŞTIR. BU ŞARTLAR
            VE KOŞULLARIN UTE AKADEMİ TARAFINDAN BİR TEKLİF OLARAK KABUL
            EDİLMESİ DURUMUNDA; KABUL, KESİNLİKLE BU ŞARTLARLA SINIRLIDIR.
          </p>
          <p>Ön tahkim maddesi:</p>
          <p>
            ÖNEMLİ: BU ŞARTLARA BAĞLI KALMAYI KABUL EDEREK UTE AKADEMİ İLE OLAN
            İHTİLAFLARI, BAĞLAYICI TAHKİM YOLUYLA (VE ÇOK SINIRLI SAYIDA
            İSTİSNAYLA, MAHKEME DIŞINDA) ÇÖZÜME ULAŞTIRMAYI VE 18. PARAGRAFTA
            AYRINTILARIYLA BELİRTİLDİĞİ ÜZERE TOPLU DAVALARA KATILMAYA İLİŞKİN
            BELİRLİ HAKLARINIZDAN FERAGAT ETMEYİ KABUL EDİYORSUNUZ.
          </p>
          <p>
            Bu Kullanım Şartları'nın Türkçe'den başka bir dilde yazılan herhangi
            bir nüshası kolaylık sağlamak amacıyla temin edilmiş olup bu
            nüshalar arasında herhangi bir ihtilaf olması halinde Türkçe dilinde
            yazılı olan nüshanın geçerli olacağını anlıyor ve kabul ediyorsunuz.
          </p>
          <h3>2. Genel</h3>
          <p>
            Hizmetlerimiz, öğrencilere ("Öğrenciler") Hizmetlerimiz aracılığıyla
            canlı, kaydedilmiş veya geliştirilmiş dersler, özel ders ve öğrenim
            hizmetleri ("Eğitimler") sağlar. Hizmetler, Eğitimler ve
            destekleyici öğrenme materyallerinin sunulması, barındırılmasını ve
            Kullanıcılardan geri bildirim alınmasını, bunlarla sınırlı kalmamak
            kaydıyla içerir.
          </p>
          <p>
            Muhtelif zamanlarda, Biz bu Şartları uygulamalarımızı açıklığı
            kavuşturmak veya yeni özellikler eklediğimizde olduğu gibi yeni ve
            farklı uygulamaları yansıtmak için güncelleyebiliriz ve UTE Akademi
            dilediği zaman bu Şartlarda tadil ve/veya değişiklik yapma hakkını
            tamamen kendi takdirine bağlı olarak saklı tutar. Biz bu Şartlarda
            herhangi bir önemli değişiklik yaparsak Hesabınızda belirtilen
            e-posta adresine e-posta ile bildirimde bulunmak ya da Hizmetlerimiz
            üzerinden bir bildiri yayınlamak gibi belirgin yolları kullanarak
            Sizi bilgilendireceğiz. Tadiller, aksi belirtilmediği takdirde
            yayınlandıkları günden itibaren yürürlüğe girecektir.
          </p>
          <p>
            Değişiklikler yürürlüğe girdikten sonra Sizin Hizmetlerimizi
            kullanmayı sürdürmeniz, Sizin bu değişiklikleri kabul ettiğiniz
            anlamına gelir. Değiştirilmiş Şartlar önceki tüm Şartları
            yürürlükten kaldırıp bunların yerini alacağı için Şartlar'ın en
            güncel halinden haberdar olduğunuzdan emin olmak üzere Hizmetleri
            düzenli aralıklarla kontrol etmelisiniz.
          </p>
          <p>
            UTE Akademi Hizmetleri herhangi bir zamanda değiştirebilir veya
            kullanımdan kaldırabilir.
          </p>
          <p>
            Hizmetlere erişim ve bunların kullanımı ile ilgili tüm hizmet,
            telefon, veri ücretleri ve/veya diğer ücretler ve maliyetlerin yanı
            sıra bu tür erişim ve kullanım için gereken tüm telefon, bilgisayar
            donanımı ve diğer ekipmanı edinmekten ve bunların bakımından
            yalnızca kendiniz sorumlusunuz.
          </p>
          <p>
            Ücret ödenmesini gerektiren Hizmetlerimize erişmeyi veya kullanmayı
            seçtiğiniz takdirde, bu ücreti ve böyle bir erişim veya kullanım ile
            ilişkili tüm vergileri ödemeyi kabul etmiş sayılacak ve bunları
            ödemekten sorumlu olacaksınız. Bu gibi ücretleri ödemek için kredi
            kartı bilgilerinizi vermeniz halinde, bu tür bilgileri vermeye
            yetkili olduğunuzu beyan etmiş ve bu vesileyle UTE Akademi’ye ödeme
            tarihi gelen ücretleri düzenli olarak kredi kartınızdan tahsil etme
            yetkisi vermiş olursunuz.
          </p>
          <p>
            Ödeme yönteminiz başarısız olur veya hesabınızın ödemesi gecikirse
            Biz, borçlu olduğunuz ücretleri diğer tahsilat mekanizmalarını
            kullanarak tahsil edebiliriz. Bu, bizde kayıtlı olan diğer ödeme
            yöntemlerinizden tahsilat yapmayı ve/veya Tahsilat Acentesi ve hukuk
            danışmanı görevlendirmeyi içerebilir. Ayrıca Kuruma ödenmemiş ve
            çözüm bekleyen herhangi bir tutar nedeniyle herhangi bir Hizmete
            erişiminizi engelleyebiliriz.
          </p>
          <p>
            Sizin Hizmetler ile alakalı tüm kullanım, erişim ve diğer
            faaliyetleriniz, telif hakkı ve diğer fikri mülkiyet kullanımına ve
            gizlilik ve kişisel kimliğe ilişkin yasalar da dahil ancak bunlarla
            sınırlı kalmamak kaydıyla tüm geçerli kanun ve düzenlemelere uygun
            olmalıdır. Buna ilaveten, Hizmetlerin içeriklerinin yasa dışı olarak
            kabul edildiği bölgelerden Hizmetler'e erişim yasaklanmıştır.
            Türkiye dışında yer alan konumlardan Hizmetler'e erişmeyi veya
            Hizmetler'i kullanmayı seçenler bunu kendi inisiyatiflerini
            kullanarak yaparlar ve bu kişiler internet, veri, e-posta veya
            gizlilik ile ilgili tüm kurallar dahil ancak bunlarla sınırlı
            kalmamak kaydıyla, tüm yerel kurallara uymakla yükümlüdürler. Ayrıca
            Siz, Türkiye’den veya ikamet ettiğiniz ülkeden verilen teknik
            verinin iletimi ile ilgili tüm geçerli yasalara uymayı kabul
            edersiniz. Türkiye dışındaki ülkelerden Hizmetler veya Üçüncü Taraf
            Platformları (bir sonraki paragrafta açıklandığı üzere)
            kullanırsanız çevrimiçi hareket ve kabul edilebilir içeriğe dair tüm
            yerel kurallara riayet edeceğinizi kabul etmeniz gerekir.
          </p>

          <h3>3. Genel Sorumluluk Reddi</h3>
          <p>
            Hizmetler, yalnızca Öğrencilere yönelik bir pazar alanıdır.
            Eğitmenler ile Hizmetlerimiz vasıtasıyla bir Eğitmenden Eğitim
            hizmeti satın alan Öğrenciler arasında geçen etkileşimlerden sorumlu
            veya yükümlü değiliz. Bir Öğrencinin, Eğitmen tarafından sağlanan
            herhangi bir bilgiye güvenmesi dahil ancak bununla sınırlı
            olmaksızın Eğitmenler veya Öğrencilerin davranışı sonucu ortaya
            çıkan veya bununla ilgili olabilecek herhangi bir anlaşmazlık,
            iddia, kayıp, yaralanma veya hasardan sorumlu değiliz.
          </p>
          <p>
            Hizmetlerde yayınlanmış Gönderilen İçerik (aşağıda tanımlandığı
            şekilde) bizim kontrolümüzde değildir. Bu nedenle, bu gibi
            Gönderilen İçeriğin güvenilirliği, geçerliliği, doğruluğu ya da
            kesinliğini hiçbir şekilde garanti etmemekteyiz. Ayrıca, Hizmetleri
            kullanarak saldırgan, uygunsuz veya sakıncalı olduğunu düşündüğünüz
            Gönderilen İçeriğe maruz kalabileceğinizi anlamaktasınız. UTE
            Akademi’nin, geçerli yasa kapsamında izin verildiği ölçüde, Sizi bu
            tür içerikten korumaya ilişkin için herhangi bir yükümlüğü ve
            herhangi bir Gönderilen İçeriğe erişim veya kullanımınız bakımından
            herhangi bir sorumluluğu yoktur.
          </p>
          <p>
            Hizmetler, size doğrudan ya da Eğitimler ve Eğitmenler üzerinden
            üçüncü taraf platformların ("Üçüncü Taraf Platformlar")
            bağlantılarına erişim verebilir. UTE Akademi, bu üçüncü taraf
            platformların hiçbirini onaylamaz ve bunları herhangi bir şekilde
            kontrol etmez. Buna bağlı olarak, UTE Akademi, Üçüncü Taraf
            Platformlar ile ilgili herhangi bir sorumluluk üstlenmez. Bir Üçüncü
            Taraf Platforma erişimin uygun olup olmadığını belirlemek ve bu gibi
            Üçüncü Taraf Platformlarda kişisel bilgilerinizi ve gizliliğinizi
            korumak için gereken adımları atmanız gerekir.
          </p>

          <h3>4. Davranış</h3>
          <p>
            Hizmetlere yalnızca meşru amaçlarla erişebilirsiniz. Hizmetlerin
            kullanımına ilişkin tüm yasaları, kuralları ve düzenlemeleri bilmek
            ve bunlara uymaktan tamamen siz sorumlusunuz. Tamamen Kendi
            takdirimize bağlı olarak sunmayabileceğimiz yazılı iznimizi önceden
            almaksızın, Hizmetleri veya Kurum İçeriğini hiçbir şekilde
            Eğitmenleri veya potansiyel kullanıcıları bizimle bağlantısı olmayan
            bir iş için istihdam etmek veya onlarla sözleşme yapmak üzere
            kullanmamayı (yukarıda belirtildiği üzere) kabul etmektesiniz. Siz
            ve herhangi bir Eğitmen veya Hizmetlerin diğer Kullanıcıları
            arasındaki tüm görüşmeler ve iletişime ilişkin her türlü risk size
            aittir.
          </p>

          <h3>5. Öğrencilere Özel Yükümlülükler</h3>
          <p>Öğrenci olarak şunları ifade, garanti ve taahhüt edersiniz:</p>
          <ol>
            <li>
              Hizmetleri kullanmadan veya bir Eğitime kayıt olmadan önce
              fiyatlandırma bilgilerini (aşağıdaki Fiyatlandırma bölümüne bakın)
              okudunuz, anladınız ve bunlara tabi olmayı kabul ettiniz.
            </li>
            <li>
              18 yaşın üstündesiniz veya değilseniz de, Hizmetleri sadece bir
              ebeveyn veya yasal vasinin katılımı, gözetimi ve onayı ile
              kullanacaksınız. 13 yaşın altındaki çocuklar, Hesap oluşturamaz ve
              Eğitimlere kayıt olamaz veya Eğitim satın alamaz.
            </li>
            <li>
              Hizmetler yoluyla herhangi bir Kullanıcıya istenmeyen veya izinsiz
              reklam, promosyon materyali, önemsiz posta, spam, zincir
              mektuplar, piramit şemalar veya (ticari veya başka türlü) istekler
              göndermeyecek, yayınlamayacak veya başka bir biçimde
              iletmeyeceksiniz.
            </li>
            <li>
              Herhangi bir uygunsuz, saldırgan, ırkçı, nefret dolu, cinsiyetçi,
              pornografik, yanlış, yanıltıcı, hatalı, ihlal edici, hakaret veya
              iftira niteliğinde içerik veya bilgi yayınlamayacaksınız veya
              temin etmeyeceksiniz.
            </li>
            <li>
              Bu Şartlar veya ilgili Eğitmen tarafından uygulanabilir olduğu
              şeklinde izin verildiği haller haricinde Kurum İçeriğini,
              Hizmetler veya Eğitimleri ya da Gönderilen İçeriği kopyalama,
              tadil etme, üzerinde ters mühendislik uygulama, çoğaltma, dağıtma,
              halka gösterim, halka açık performans, halka iletme, türev
              çalışmalar oluşturma, tahrif etme, tahrip etme, hackleme, üzerinde
              oynama yapma veya diğer yollarla kullanmanız ve bunlardan diğer
              şekillerde yararlanmanız yasaktır.
            </li>
            <li>
              Hizmetlerden kaçınmak için Hizmetleri çerçeve içinde göstermeyecek
              ya da sitenize yerleştirmeyeceksiniz.
            </li>
            <li>
              Başka bir kişinin kimliğine bürünmeyecek veya başka bir kişinin
              Hesabına yetkisiz erişim sağlamayacaksınız.
            </li>
            <li>
              Hizmetlere veya bunlarla ilgili diğer işleyişlere ait hiçbir
              donanım, yazılım veya hiçbir haberleşme teçhizatının içine bunlara
              zarar vermek üzere tasarlanan veya bunların işleyişlerini gasp
              etmesi mümkün olan virüs, solucan yazılım, casus yazılım veya
              başka bir bilgisayar kodu, dosya ya da program sokmayacaksınız;
              Hizmetlere erişmek için bir internet sitesinden veri kopyalayan
              bir program, web gezgini, robot kullanımı veya herhangi başka
              türden otomatikleştirilmiş yolları kullanmayacaksınız.
            </li>
            <li>
              Eğitmenlere kişisel bilgilerinizi açıklamayacak ve aksi takdirde,
              kişisel bilgilerinizin, bu tür bilgileri korumak için uygun
              önlemleri almak dahil ancak bununla sınırlı olmamak üzere nasıl
              ifşa edildiği veya kullanıldığını kontrol etmek için sorumluluk
              üstleneceksiniz.
            </li>
            <li>
              Herhangi Eğitmenden veya başka bir Öğrenciden kişisel bilgilerini
              talep etmeyeceksiniz.
            </li>
          </ol>
          <p>
            Öğrenciler, Öğrencilerin kendilerinden Eğitim hizmetleri almış
            olabilecekleri Eğitmenlerin UTE Akademi’deki Hesaplarını sonlandırıp
            aktif olmayan bir duruma geçmeyi seçebileceklerini kabul ederler.
            UTE Akademi, Öğrencilere Eğitmen hazır bulundurmayı garanti etmez ve
            Eğitmen hazır bulundurmaya ilişkin hiçbir sorundan ötürü sorumlu
            tutulmayacaktır.
          </p>

          <h3>6. Kayıt</h3>
          <p>
            Belirli Hizmetleri kullanmak için kayıt olmanız ve bir hesap ile bir
            parola almanız gerekir. Kayıt olduğunuzda, kayıt işlemi sırasında
            Bize verdiğiniz bilgiler içerik, müşteri hizmetleri ve ağ yönetimi
            sunmakta Bize yardımcı olacaktır. Hesabınız, Kullanıcı Adınız ve
            parolanızın (topluca, "Hesap") gizliliğini korumaktan ve Hesabınız
            altında veya Hesabınızla ilişkili olarak gerçekleşen tüm
            etkinliklerden yalnızca Siz sorumlu olacaksınız. Hesap
            bilgilerinizin her zaman doğru olacağını beyan ve garanti
            ediyorsunuz. (a) Hesabınızın izinsiz kullanılması veya başka bir
            güvenlik ihlalini Bize hemen bildirmeniz ve (b) Hizmetleri her
            kullanışınız sonunda Hesabınızdan çıkış yaptığınızdan emin olmanız
            gerekir. Yukarıda belirtilen şartlara uymamanız veya Hesabınızın,
            bilginiz dahilinde olsun ya da olmasın kullanımından, Hesabınıza
            yetkisiz erişimi Bize bildirilmeden önce doğan kayıp veya
            zararlardan, geçerli yasa kapsamında izin verilen ölçüde, sorumlu
            tutulamayız ve tutulmayacağız.
          </p>
          <p>
            Hesabınızı başka bir kişiye devredemez ve herhangi bir zamanda
            başkasının Hesabını kullanamazsınız. Hesabınızı kullanması için
            reşit olmayan biri de dahil olmak üzere başka bir bireye yetki
            verdiğiniz veya onları kaydettiğiniz durumlarda (i) bu Öğrencinin
            İnternet üzerindeki davranışından; (ii) söz konusu Öğrencinin
            Hizmetlere erişimini ve Hizmetleri kullanımını denetlemekten; ve
            (iii) herhangi bir yanlış kullanımın sonuçlarından tamamen Siz
            sorumlu olacaksınız.
          </p>

          <h3>7. İçerik, Lisanslar ve İzinler</h3>
          <p>
            Tüm yazılım, teknoloji, tasarımlar, materyal, bilgi, iletişim,
            metin, grafik, bağlantılar, elektronik sanat, animasyonlar,
            çizimler, sanat eserleri, ses klipleri, video klipler, fotoğraflar,
            görüntüler, incelemeler, fikirler ve diğer veri ya da telif hakkı
            olabilecek materyal veya içerik, bunların seçimi ve düzenlemeleri de
            dahil olmak üzere "İçerik" olarak kabul edilir. UTE Akademi Size
            Hizmetler ile bağlantılı yazılım, ürün ve site dahil ancak bunlarla
            sınırlı olmaksızın İçerik sağladığında, bunlar "Şirket İçeriği"
            olarak kabul edilir. Kullanıcılar tarafından Hizmetlere yüklenen,
            iletilen veya yayınlanan içerik, "Gönderilen İçerik" olarak kabul
            edilir. İçerik, onu sağlayan kişi veya kuruluşun (veya onlara bağlı
            ve/veya üçüncü taraf sağlayıcılar ve tedarikçiler) özel mülkiyetinde
            kalır ve herhangi bir sınırlama olmaksızın, Türkiye’deki telif hakkı
            ve diğer fikri mülkiyet yasaları uyarınca korunmaktadır.
            Tarafınızdan Gönderilen İçerik ile ilgili olarak bu Şartlarda
            belirtilen hakları vermek için gerekli tüm lisanslar, haklar,
            rızalar ve izinlere sahip olduğunuzu ve Size ait Gönderilen İçeriği
            bu Şartlarda izin verilen şekilde kullanmak veya ondan yararlanmak
            için UTE Akademi’nin üçüncü taraflardan herhangi bir lisans, hak,
            rıza veya izin alması ya da herhangi bir ödeme yapması gerekmediğini
            ve Size ait Gönderilen İçeriği bu Şartlarda izin verilen herhangi
            bir şekilde kullanmak veya yararlanmak sonucunda Siz veya bir diğer
            tarafa karşı yükümlülüğü olmadığını beyan ve garanti ediyorsunuz.
          </p>
          <p>
            UTE Akademi bu vesileyle Size (bir Kullanıcı olarak) tüm gerekli
            ücretleri ödemiş olmanız koşuluyla, Gönderilen İçerik ve Kurum
            İçeriğini Hizmetler üzerinden bu Hükümler ve belirli Eğitimler ve
            Hizmetler ile ilgili şartlar ve kısıtlamalar dahilinde yalnızca
            kişisel, ticari olmayan, eğitim amaçlı olarak kullanmak ve onlara
            erişebilmek için sınırlı, münhasır olmayan, devredilemez bir lisans
            vermektedir. Diğer tüm kullanımlar bizim açık yazılı iznimiz
            olmadıkça kesinlikle yasaktır. Bunun için Size açıkça izin
            vermediğimiz sürece, herhangi bir Gönderilen İçerik veya Kurum
            İçeriğini çoğaltma, yeniden dağıtma, iletme, atama, satma,
            yayınlama, kiralama, paylaşma, ödünç verme, değiştirme, adapte etme,
            düzenleme, türevi çalışmalar oluşturma ya da lisansını verme veya
            başka türlü devretmeniz yasaktır. Gönderilen İçerik ve Kurum İçeriği
            için size lisans verilir; bunlar Size satılmaz. Eğitmenler Size
            Hizmetler yoluyla eriştiğiniz veya edindiğiniz Gönderilen İçerik
            için lisans izni veremez ve bu gibi doğrudan lisanslar geçersiz
            sayılacak ve bu Koşulların ihlaline yol açacaktır.
          </p>
          <p>
            Yukarıdaki hükümlere bakılmaksızın, Biz Size yukarıda belirtildiği
            şekilde erişim ve kullanım izni verdiğimiz Gönderilen İçeriğin ve
            Kurum İçeriğinin erişim ve kullanım lisansını geri alma hakkını
            saklı tutuyoruz; bu konu aşağıdaki paragrafta daha detaylı bir
            şekilde belirtilmiştir.
          </p>
          <p>
            UTE AKADEMİ, İÇERİK VE BİLGİYE İLİŞKİN TELİF HAKKI, GİZLİLİK, İFTİRA
            VE DİĞER KONULARDAKİ TÜM YASALARA SAYGILIDIR VE BU YASALARIN
            İHLALİNE MÜSAMAHA GÖSTERMEYECEKTİR. YUKARIDAKİ HÜKÜMLERE
            BAKILMAKSIZIN, UTE AKADEMİ GÖNDERİLEN İÇERİĞİ DENETLEMEMEKTE OLUP
            GÖNDERİLEN İÇERİĞİN TARAFINIZDAN KULLANIMINA İLİŞKİN TÜM RİSKLER
            SİZE AİTTİR VE UTE AKADEMİ’NİN BU GİBİ KULLANIMLA İLGİLİ HERHANGİ
            BİR YÜKÜMLÜLÜĞÜ OLMAYACAKTIR. ÖZELLİKLE DE, HİZMETLER ÜZERİNDE VEYA
            HİZMETLER YOLUYLA, GÖNDERİLEN İÇERİĞİN HERHANGİ BİR İNCELEMESİ,
            YAYINI VEYA GÖRÜNTÜSÜ, SÖZ KONUSU GÖNDERİLEN İÇERİĞİN TELİF HAKKI,
            GİZLİLİK VE DİĞER KANUNLARI HİÇBİR ŞEKİLDE İHLAL ETMEDİĞİNE VEYA
            BELLİ BİR AMACA UYGUN YA DA HATASIZ VE YARARLI OLDUĞUNA DAİR BİR
            BEYAN VEYA ONAY TEŞKİL ETMEYECEKTİR. Size ait Gönderilen İçeriğin
            yasaları veya mevzuatı ihlal ettiğini veya yanlış olduğunu ya da bir
            üçüncü taraf için herhangi bir risk teşkil ettiğini düşünüyorsanız
            durumu düzeltmek için gerekli gördüğünüz adımları atmak Sizin
            sorumluluğunuzdadır. Üçüncü taraflarca Gönderilen İçerik ya da
            herhangi bir Kurum İçeriğinin telif hakkı yasaları dahil ancak
            bunlarla sınırlı olmaksızın herhangi bir yasa ve yönetmeliği ihlal
            ettiğini düşünüyorsanız bu durumu Bize bildirmeniz gerekir.
          </p>
          <p>
            Bu Şartlarda açıkça belirtilmeyen tüm haklar, İçerik sahiplerinde
            kalacak ve bu Şartlar herhangi bir zımni lisans tahsis etmeyecektir.
          </p>
          <p>
            Siz, Bize yeni tanıtımlar, ürünler, hizmetler, uygulamalar,
            teknolojiler veya işlemler ya da başka fikirler dahil olmak üzere
            (topluca "Kullanıcı Fikirleri") istenmeden verilmiş fikirler
            göndermeye karar verebilirsiniz. Gizli olduğunu veya özel sahiplik
            içerdiğini düşündüğünüz hiçbir Kullanıcı Fikrini Hizmetler veya
            Üçüncü Taraf Platformlar üzerinden veya Bize e-posta üzerinden
            iletmemeniz gerekir. Siz, bizim hiçbir Kullanıcı Fikrini gizli veya
            özel fikirler olarak ele almak zorunda olmayacağımızı kabul
            edersiniz. Gönderdiğiniz tüm Kullanıcı Fikirlerinden Siz sorumlu ve
            yükümlüsünüzdür. Siz, Bize herhangi bir kavram, uzmanlık bilgisi
            veya fikir içeren Kullanıcı Fikirleri göndererek Bize bu vesile ile
            Kullanıcı Fikirlerini, tüm Kullanıcı Fikirlerinin (ve bunlardan
            türemiş çalışmaların) bir kısmının veya tamamının herhangi bir medya
            formatında ve halihazırda bilinmekte olan ya da bundan sonra
            geliştirilecek tüm medya kanalları aracılığıyla Size veya
            başkalarına ödeme yapılmaksızın veya hesap verilmeksizin tanıtımı ve
            yeniden dağıtımı dahil ancak bunlarla sınırlı kalmamak kaydıyla,
            Hizmetler ile bağlantılı olarak ve UTE Akademi’nin (ve haleflerinin)
            işi için kullanma, çoğaltma, dağıtımını yapma, satma, kendi çıkarına
            kullanma, türev işlerini hazırlama ve görüntüleme için daimi, tüm
            dünyada geçerli, münhasır olmayan, telifsiz, alt
            lisanslandırılabilir ve devredilebilir bir lisans vermiş olduğunuzu
            kabul edersiniz. Kullanıcı Fikirlerini değerlendirmek, gözden
            geçirmek veya kullanmak konusunda Biz, hiçbir yükümlülük
            üstlenmeyiz.
          </p>
          <h3>8. Fiyatlandırma, Ödeme ve Vergiler</h3>
          <p>
            UTE Akademi Eğitimlerinin fiyatlandırılması, UTE Akademi’nin
            öngördüğü piyasa ve sektör analizleri kapsamında ortaya konulan
            sonuçlar gereğince kararlaştırılır. Öğrenci olarak satın aldığınız
            Eğitim hizmetlerinin ücretlerini ödemeyi kabul edersiniz ve bu
            vesile ili Bizi, bu ücretleri Sizin kredi kartınızdan alma konusunda
            yetkilendirirsiniz. Kredi kartınız kabul edilmezse Bize bu ücretleri
            Bizim size yaptığımız bildirimden itibaren en geç otuz (30) gün
            içerisinde ve (Bizim takdirimize bağlı olarak) her bir ay başına
            %1,5 oranında ya da yasalar tarafından izin verilen maksimum
            miktarda olmak üzere, hangisi en fazla ise o baz alınarak uygulanan
            gecikme cezası ücreti ile birlikte ödemeyi kabul edersiniz.
          </p>

          <p>
            Bir Eğitimin herhangi bir Öğrenciye satışı veya teslimatının
            herhangi bir katma değer vergisine ("KDV") tabi olması durumunda,
            UTE Akademi geçerli yasalar kapsamında, Eğitimlerin satışı için KDV
            tahsil edecek ve yetkili vergi dairelerine havale edecektir. UTE
            Akademi, KDV ödenmesi gerektiğini ve bu bakımdan yükümlülük altında
            olacağını düşündüğü durumlarda Satış Fiyatını kendi takdirine bağlı
            olarak artırabilir. Herhangi bir vergi dairesinin KDV'nin eksik
            ödendiği iddiası ve bunlarla ilgili herhangi bir ceza ve/veya
            faizden UTE Akademi’yi sorumlu tutmayacak ve UTE Akademi’ye gerekli
            tutarı tazmin edeceksiniz.
          </p>

          <p>
            UTE Akademi tamamen kendi takdirine bağlı olarak, Satış Fiyatını bu
            fiyatın verildiği Satış Para Birimindeki en yakın tam sayıya (ör. en
            yakın türk lirası veya diğer desteklenen para birimine)
            yuvarlayabilir. Örneğin, UTE Akademi 101,50 TL’yi 102,00 TL’ye ve
            101,49 TL’yi 101,00 TL’ye yuvarlar.
          </p>

          <h3>9. Garanti Sorumluluk Reddi</h3>
          <p>
            HİZMETLERDE VEYA HİZMETLERİN KULLANIMI ARACILIĞIYLA KULLANIMA
            SUNULAN HİZMETLER, KURUM İÇERİĞİ, GÖNDERİLEN İÇERİKLER, KURSLAR VE
            TÜM DİĞER MATERYALLER "OLDUĞU GİBİ" VE DOĞRUDAN VEYA DOLAYLI HİÇBİR
            TÜRDEN GARANTİ OLMAKSIZIN SUNULMAKTADIR. GEÇERLİ KANUN UYARINCA İZİN
            VERİLEBİLİR OLAN EN SON DERECEYE KADAR, UTE AKADEMİ VE ONUN
            İŞTİRAKLERİ, LİSANSÖRLERİ, TEDARİKÇİLERİ, REKLAMCILARI, SPONSORLARI
            VE GÖREVLİLERİ ZAPTA KARŞI TEMİNAT, İHLAL ETMEME, HATASIZLIK,
            PAZARLANABİLİRLİK VE BELLİ BİR AMACA UYGUNLUK GARANTİLERİ DE DAHİL
            ANCAK BUNLARLA SINIRLI OLMAMAK KAYDIYLA DOĞRUDAN VEYA DOLAYLI BÜTÜN
            GARANTİLERİ VE TİCARET ANLAŞMALARI ESNASINDA, İCRA ESNASINDA VEYA
            TİCARİ ÖRFTEN DOĞABİLECEK TÜM GARANTİLERİ TEKZİP EDER. UTE AKADEMİ
            VE İŞTİRAKLERİ, LİSANSÖRLERİ, TEDARİKÇİLERİ, REKLAMCILARI,
            SPONSORLARI VE GÖREVLİLERİ HİZMETLERİ KULLANIMINIZIN KESİNTİSİZ,
            HATASIZ VEYA GÜVENLİ OLACAĞINI, KUSURLARIN DÜZELTİLECEĞİNİ VEYA
            HİZMETLERİN, GÖNDERİLERİN, İÇİNDE HİZMETLERİN BULUNDUĞU SUNUCULARIN
            VEYA ÜÇÜNCÜ TARAF PLATFORMLARDA KULLANIMA SUNULAN HİZMETLERİN VİRÜS
            VEYA DİĞER ZARARLI BİLEŞENLERDEN YOKSUN OLDUĞUNU GARANTİ ETMEZ. UTE
            AKADEMİ VE İŞTİRAKLERİ, LİSANSÖRLERİ, TEDARİKÇİLERİ, REKLAMCILARI,
            SPONSORLARI, GÖREVLİLERİ, ÜYELERİ VEYA ZİYARETÇİLERİNİN HİZMETLER YA
            DA ÜÇÜNCÜ TARAF PLATFORMLAR KULLANILARAK YA DA BAŞKA BİR BİÇİMDE
            BELİRTİLMİŞ OLAN HİÇBİR FİKRİ, TAVSİYESİ VEYA İFADESİ BİR GARANTİ
            OLUŞTURMAYACAKTIR. ÜÇÜNCÜ TARAF PLATFORMLARINDA SUNULAN HİZMETLER
            DAHİL ANCAK BUNLARLA SINIRLI OLMAMAK KAYDIYLA TÜM HİZMETLERİ
            KULLANIMINIZDAN DOĞABİLECEK RİSKLER TAMAMEN SİZE AİTTİR.
          </p>
          <h3>10. Sorumluluğun Sınırlandırılması</h3>
          <p>
            HİZMETLER VEYA HERHANGİ BİR ÜÇÜNCÜ TARAF PLATFORM ARACILIĞIYLA
            ERİŞİLEBİLİR VE KULLANILABİLİR OLAN, ÜÇÜNCÜ TARAF PLATFORMLARDA
            KULLANIMA HAZIR BULUNAN TÜM MATERYALLER DAHİL OLMAK ÜZERE,
            GÖNDERİLER, BAĞLI WEB SİTELERİ VEYA KODLAR, SATIN ALINAN ÜRÜNLER
            VEYA HİZMETLER DAHİL HİZMETLER VE/VEYA MALZEMELERDEN KAYNAKLANAN
            VEYA BUNLARLA HERHANGİ BİR ŞEKİLDE İLİŞKİLİ OLAN HİÇBİR SÖZLEŞME,
            İHMALKARLIK, KUSURSUZ SORUMLULUK VEYA DİĞER KURAMLAR GEREĞİNCE UTE
            AKADEMİ VEYA ALT KURULUŞLARIMIZ, İŞTİRAKLERİMİZ, LİSANSÖRLERİMİZ,
            TEDARİKÇİLERİMİZ, REKLAMCILARIMIZ, SPONSORLARIMIZ VE
            GÖREVLİLERİMİZDEN HİÇBİRİSİ VEYA BİZİM VEYA ONLARIN YÖNETİCİLERİ,
            YETKİLİLERİ, ÇALIŞANLARI, DANIŞMANLARI, GÖREVLİLERİ VEYA DİĞER
            TEMSİLCİLERİ DOLAYLI, RASTLANTISAL, RİSK SEBEBİYLE OLUŞAN, ÖZEL,
            CEZAİ, CEZALANDIRICI VEYA DİĞER TÜRLÜ (İŞ KAYBI TAZMİNATI, VERİ
            KAYBI TAZMİNATI VEYA KAR MAHRUMİYETİ TAZMİNATLARI DA DAHİL ANCAK
            BUNLARLA SINIRLI OLMAKSIZIN) TAZMİNATLARDAN SORUMLU VE YÜKÜMLÜ
            DEĞİLDİR. HERHANGİ BİR ÜÇÜNCÜ TARAF PLATFORM ARACILIĞIYLA
            ERİŞİLEBİLİR VE KULLANILABİLİR OLAN ÜRÜNLER VE HİZMETLER, GÖNDERİLER
            VEYA BAĞLI SİTELER DAHİL HİZMETLER MALZEMELERİN KULLANIMINDAN HOŞNUT
            KALMADIĞINIZDA, SİZİN TEK VE YEGANE ÇARENİZ, UYGUN OLAN ŞEKİLDE
            HİZMETLERİ, MALZEMELERİ, GÖNDERİLERİ, ÜRÜNLERİ VEYA BAĞLI WEB
            SİTELERİNİ KULLANMAYI DURDURMANIZDIR. BU KULLANIM ŞARTLARINDAKİ
            TAZMİNAT İSTİSNALARININ, HERHANGİ BİR YASAL ÇÖZÜM YOLUNUN ASLİ
            AMACINA ULAŞAMADIĞI TAKDİRDE DAHİ GEÇERLİ OLACAĞINI KABUL EDERSİNİZ.
          </p>
          <p>
            Hatalar. Bir Üçüncü Taraf Platformda sağlanan Hizmetlerin hatalar
            veya yanlışlıklar ya da bu Şartları ihlal eden bilgi veya malzemeler
            barındırabilmesi olasılığı mevcuttur. Buna ek olarak, herhangi bir
            Üçüncü Taraf Platformda kullanıma hazır bulunan Hizmetler üzerinde
            üçüncü taraflarca yetkisiz değişiklikler yapılabilmesi gibi bir
            olasılık da mevcuttur. UTE Akademi, Üçüncü Taraf Platformlardaki
            Hizmetlerin bütünlüğünü temin etmeye çalışsa da, Hizmetlerin tamlığı
            veya kusursuzluğuna ilişkin bir garanti vermiyoruz. Hizmetlerin
            tamlığı ya da kusursuzluğunun sorgulandığı bir durum ortaya çıkması
            halinde, lütfen iletisim@ispasaportum.com.tr adresine (konu satırına
            "Üçüncü Taraf Platformu (Adı) üzerindeki HizmetIerde bulunan
            Hatalar" yazarak) ve tebliğ kısmına, mümkünse kontrol edilecek
            Hizmetlerin bir tanımı ve bu Hizmetlerin Bizim Hizmetlerimiz veya
            Üçüncü Taraf Platformu üzerinde bulunduğu konumu (URL) yazıp Bizim
            Sizinle iletişim kurmamızı mümkün kılacak yeterlilikte bilgi de
            vererek bir talep gönderiniz. Endişelerinizi mantık çerçevesinde
            uygulanabilir olan en kısa zamanda ele almaya çalışacağız.
          </p>
          <p>
            Sistem Kesintileri. UTE Akademi, bakım ve diğer amaçlar
            doğrultusunda Hizmetler için düzenli aralıklarla sistem aksama
            süreleri planlar. Buna ek olarak planlanmamış sistem kesintileri de
            meydana gelebilir. Siz şunlar için UTE Akademi’nin hiçbir
            sorumluluğu olmadığını ve mesuliyet üstlenmediğini kabul edersiniz:
            (a) Üçüncü Taraf Platformlardakiler dahil Hizmetlerin kullanıma
            hazır bulunmama durumu; (b) bu tarz sistem kesintilerinden
            kaynaklanan her tür malzeme, veri, işlem veya başka herhangi bir
            bilgi veya malzemelerin kaybı; (c) bu tarz sistem kesintilerinden
            kaynaklanan gecikme, veri, işlem veya başka herhangi bir bilgi veya
            malzemelerin yanlış isme gönderimi ya da gönderilememesi veya (d)
            Hizmetleri barındıran sunucular veya şirketler, internet servis
            sağlayıcıları, Üçüncü Taraf Platformlar veya internet tesisleri ve
            ağları dahil ancak bunlarla sınırlı olmamak kaydıyla tüm üçüncü
            taraflardan kaynaklan kesintiler.
          </p>
          <h3>12. Fesih</h3>
          <p>
            UTE Akademi olarak (a) Gönderilen İçerik, Şirket İçeriği, Kurslar
            veya herhangi bir Hizmeti ve (b) Hizmetlerimize veya Hesabınıza
            erişiminizi sonlandırma, askıya alma, değiştirme veya silme hakkını
            Kendi takdirimize bağlı olarak aşağıdaki belirtildiği gibi saklı
            tutarız:
          </p>
          <ol>
            <li>
              Bu Koşulların veya muhtelif zamanlarda Hizmetlerimiz üzerinden
              yayınlanan yürürlükteki ilkelerimizin herhangi birine uymaz veya
              bunları ihlal ederseniz UTE Akademi, önceden haber vermeksizin
              size derhal dava açabilir. Bu bölüm uyarınca dava açılması
              durumunda satın almış olabileceğiniz Eğitimlerin ve Hesabınızla
              ilişkili Hizmetlerimizin kullanımına ilişkin Size karşı herhangi
              bir sorumluluğumuz yoktur. Satın almış olabileceğiniz
              Eğitimlerimiz dahil olmak ancak bunlarla sınırlı olmamak kaydıyla,
              Hizmetlerimize ilişkin erişim kaybınızın hiçbir koşul altında
              telafisini veya geri ödemesini almayacağınızı anlıyor ve kabul
              ediyorsunuz;
            </li>
            <li>
              Herhangi bir sebep olmaksızın veya herhangi bir sebepten ötürü
              dava açabiliriz, bu durumda Biz sizi önceden bilgilendireceğiz. Bu
              paragraf uyarınca dava açarsak öğrenciyseniz bu fesihten önceki üç
              (3) aylık zaman dilimi içerisinde satın almış olabileceğiniz
              Eğitimlere yönelik erişim kaybınız için hepsi bu Şartlar ile
              belirtilmiş olan geçerli ödeme politikamızın şartları ile uyum ve
              bağlılık içinde olmak üzere Size geri ödeme yapacağız.
            </li>
          </ol>
          <p>
            Hizmetleri kullanımınızı dilediğiniz zaman sonlandırabilirsiniz.
            Bunu UTE Akademi Hesabınızı silme yoluyla gerçekleştirebilirsiniz.
            Sizin Hesabınız veya Gönderilen İçeriklerinizi geçerli kanunlar
            tarafından gerekli görülebilecek olan zaman diliminin ötesinde
            saklamak ile ilgili hiçbir yükümlülüğümüz yoktur. Fesih üzerine,
            Hizmetler ve İçeriğin tüm kullanımına son vermeniz gerekir. Tahakkuk
            etmiş tüm ödeme hakları, tüm beyanlar ve tüm garantiler fesihten
            sonra geçerliliğini korumaya devam edecektir.
          </p>

          <h3>13. Elektronik Bildirimler</h3>
          <p>
            Hizmetlerimizi kullanarak veya UTE Akademi ile iletişime geçerek,
            Sizin Hizmetleri veya buradaki Şartları kullanımınıza dair güvenlik,
            gizlilik ile ilgili ve idari konular hakkında elektronik olarak
            Sizinle iletişime geçebileceğimizi kabul edersiniz. UTE Akademi bir
            güvenlik sistemi ihlalinden haberdar olursa Hizmetler üzerinden bir
            bildirim yayınlamak veya Size bir e-posta göndermek suretiyle Sizi
            elektronik olarak bilgilendirmeye çalışabilir. Bu bildirimi yazılı
            bir şekilde almak gibi yasal bir hakka sahip olabilirsiniz. Bir
            güvenlik ihlali hakkında ücretsiz yazılı bildirim almak (veya
            elektronik bildirim almaya verdiğiniz onayı geri çekmek) için lütfen
            iletisim@ispasaportum.com.tr adresinden Bize yazın. E-postayı
            gönderen tarafa e-posta adresinin geçersiz olduğu yönünde bir
            bildirim yapılmaması halinde bildirim, e-posta gönderildikten 24
            saat sonra yerine ulaştırılmış olarak kabul edilecektir. Alternatif
            olarak, UTE Akademi, Hizmetlerden herhangi birini kullanırken Sizin
            sağlamış olduğunuz bir adres var ise bu adrese posta göndermek
            suretiyle de Size yasal bildirim gönderebilir. Bu durumda, bildirim
            postalama tarihinden üç gün sonra elinize ulaştırılmış olarak kabul
            edilecektir.
          </p>

          <h3>14. Gizlilik ve Güvenlik</h3>
          <p>
            UTE Akademi’yi kullandığınızda kişisel bilgileriniz, sadece sizin
            bilginiz dahilinde sizden temin edilir. Sizin bilginiz ile bizim
            tarafımızdan kayıt altına alınan kişisel bilgileriniz UTE Akademi
            tarafından korunur. UTE Akademi üyelik bilgilerinizi UTE Akademi
            ürün ve hizmetleri hakkında sizi bilgilendirmek amacıyla saklar.
            Bunun yanı sıra UTE Akademi, ispasaportum.com.tr ve ona bağlı tüm
            hizmetler kapsamındaki anonim kullanım verileri toplama hakkında
            sahiptir. UTE Akademi tarafından toplanan anonim kullanım verileri
            sistem iyileştirmeleri, öğrenme analitikleri ve pazar araştırmalar
            kapsamında kullanılabilir. Söz konusu kişisel bilgiler sizin
            haberiniz ya da onayınız olmadan ya da hukuksal yükümlülük altında
            bulunulmadığı sürece üçüncü parti kişi ve kurumlarla
            paylaşılmayacaktır.
          </p>

          <p class="small text-right">
            Bu belge, en son 14 Ağustos 2020 tarihine güncellenmiştir.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "Kullanım Şartları"
  }
};
</script>
